import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SeguridadService } from '../services/seguridad.service';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoGuard implements CanActivate {
  constructor(
    private seguridadService: SeguridadService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let rol = this.seguridadService.obtenerRolUsuario();
    if (
      (localStorage.getItem('datosSesion') && this.seguridadService.obtenerInfoSesion().estaIdentificado) &&
      (
        rol === 'Administrador' ||
        rol === 'Backoffice' ||
        rol === 'Vendedor' ||
        rol === 'PetManager' ||
        rol === "Veterinario"
      )
    ) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
