import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SeguridadService } from '../services/seguridad.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private seguridadService: SeguridadService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.seguridadService.obtenerToken();
    // Desactivar header a ciertas url
    if (req.url.startsWith('https://api.imgbb.com')) {
      return next.handle(req);
    }
    const headers = token ? new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Rol':this.seguridadService.obtenerRolUsuario(),
      'EmpleadoId': this.seguridadService.obtenerIdPersona()
    }) : undefined;
    const modifiedRequest = headers ? req.clone({ headers }) : req;
    return next.handle(modifiedRequest);
  }
}
