import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuracion } from '../config/configuracion';
import { ClienteModel } from '../modelos/cliente.model';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(
    private http: HttpClient,
  ) { }
  
  DatosBasicos(id: string): Observable<ClienteModel> {
    return this.http.get<ClienteModel>(`${Configuracion.urlbackend}/clientes/${id}?filter={
      "fields":["id","nombres","apellidos","fotoThumb"]      
    }`);
  }

  BuscarClientes(text: string):Observable<ClienteModel[]>{
    return this.http.get<ClienteModel[]>(`${Configuracion.urlbackend}/clientes/buscar/${text}`);
  }

  ObtenerClienteId(id: string): Observable<ClienteModel> {
    return this.http.get<ClienteModel>(`${Configuracion.urlbackend}/clientes/${id}`);
  }

  ClientesPorVendedor(id: string): Observable<ClienteModel[]> {
    return this.http.get<ClienteModel[]>(`${Configuracion.urlbackend}/clientesporvendedor/${id}`);
  }

  ClientesPorEstado(estado: string): Observable<ClienteModel[]> {
    return this.http.get<ClienteModel[]>(`${Configuracion.urlbackend}/clientes?filter={
      "where":{"estado":"${estado}"},
      "fields":["id","documento","nombres","apellidos","fotoThumb"] 
    }`);
  }

  ObtenerClientePorDocumento(documento: string): Observable<ClienteModel> {
    return this.http.get<ClienteModel>(`${Configuracion.urlbackend}/clientes/documento/${documento}?filter={
      "fields":["id","nombres","apellidos"]
    }`)
  }

  infoCliente(id: string): Observable<ClienteModel> {
    return this.http.get<ClienteModel>(`${Configuracion.urlbackend}/clientes/${id}?filter={
      "include":[
        {
          "relation":"ciudad",
          "scope":{
            "fields":["id","nombre"]            
          }
        }
      ],
      "fields":["id","documento","nombres","apellidos","celular","telefono","direccion","correo","ciudadId","fotoThumb","foto"]
    }`);
  }

  infoClienteConCiudad(id: string): Observable<ClienteModel> {
    return this.http.get<ClienteModel>(`${Configuracion.urlbackend}/clientes/${id}?filter={
      "include":[
        {
          "relation":"ciudad",
          "scope":{
            "fields":["id","nombre"]            
          }
        }
      ],
      "fields":["id","documento","nombres","apellidos","celular","direccion","ciudadId","foto","fotoThumb"]
    }`);
  }

  CrearCliente(cliente: ClienteModel): Observable<ClienteModel> {
    return this.http.post<ClienteModel>(`${Configuracion.urlbackend}/clientes`, cliente);
  }

  ActualizarCliente(cliente: ClienteModel): Observable<ClienteModel> {
    return this.http.patch<ClienteModel>(`${Configuracion.urlbackend}/clientes/${cliente.id}`, cliente)
  }

  
}
