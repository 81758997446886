import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Md5 } from 'ts-md5';
import { PersonaModel } from '../modelos/persona.model';
import { ClienteService } from '../services/cliente.service';
import { DataService } from '../services/data.service';
import { EmpleadoService } from '../services/empleado.service';
import { SeguridadService } from '../services/seguridad.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  msnError: string = "";
  mostrarError: boolean = false;
  loginForm: FormGroup = this.fb.group({
    usuario: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  btn = true;
  loading: boolean = false;

  constructor(
    public fb: FormBuilder,
    public servicioSeguridad: SeguridadService,
    private router: Router,
    public toast: ToastController,
    private seguridadService: SeguridadService,
    private clienteService: ClienteService,
    private empleadoService: EmpleadoService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loading = false;
    const usuario = this.servicioSeguridad.obtenerInfoSesion();
    if (usuario) {
      this.verificarInicio(usuario);
    }
  }

  entrar() {
    this.btn = false;
    this.loading = true;
    const usuario = this.loginForm.controls.usuario.value.toLowerCase();
    const password = this.loginForm.controls.password.value;
    if (usuario && password) {
      this.mostrarError = false;
      const passwordCifrada = Md5.hashStr(password);
      this.servicioSeguridad.login(usuario, passwordCifrada).subscribe((data: any) => {
        if (data.datos.id) {
          this.servicioSeguridad.almacenarSesion(data);
          this.verificarInicio(data);
          this.loginForm.reset();
        } else {
          this.error(data.datos.mensaje);
          this.loginForm.controls.password.reset();
          this.loading = false;
        }
        this.btn = true;
      }, (err: any) => {
        this.toastError('No se pudo conectar con el servidor', 'Error de conexión');
        this.btn = true;
        this.loading = false;
      });
    } else {
      this.error("Por favor ingrese usuario y contraseña");
      this.loading = false;
    }
  }

  error(msn: string) {
    this.mostrarError = true;
    this.msnError = msn;
  }

  async toastError(mensaje: string, header: string = 'Error') {
    const toast = await this.toast.create({
      header: header,
      message: mensaje,
      duration: 3000,
      position: 'top',
      color: 'danger'
    });
    toast.present();
  }

  verificarInicio(data: any) {
    switch (data.datos.rol) {
      case "Administrador":
      case "Backoffice":
      case "PetManager": {
        this.datosEmpleado('/empleado', data);
        break;
      }
      case 'Veterinario': {
        this.datosEmpleado('/empleado/citas-pendientes', data);
        break;
      }
      case 'Vendedor': {
        this.datosEmpleado('/empleado/clientes-vendedor', data);
        break;
      }
      case 'Domiciliario': {
        this.datosEmpleado('/empleado/domicilios', data);
        break;
      }
      case 'cliente': {
        this.clienteService.DatosBasicos(this.seguridadService.obtenerIdPersona()).subscribe((cliente: PersonaModel) => {
          data.persona = cliente;
          this.servicioSeguridad.almacenarSesion(data);
          this.router.navigate(['/cliente']);
        })
        break;
      }
      default: {
        this.router.navigate(['/']);
        console.log('Quien es?');
        break;
      }
    }
  }

  datosEmpleado(url: string, data: any) {
    this.empleadoService.DatosBasicos(this.seguridadService.obtenerIdPersona()).subscribe((empleado: PersonaModel) => {
      data.persona = empleado;
      this.servicioSeguridad.almacenarSesion(data);
      this.router.navigate([url]);
    })
  }

}
