import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ClienteGuard } from './guards/cliente.guard';
import { EmpleadoGuard } from './guards/empleado.guard';
import { IdentificadoGuard } from './guards/identificado.guard';
import { LoginPage } from './login/login.page';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'login',
    pathMatch: 'full',
    component: LoginPage,
    canActivate: [IdentificadoGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'recuperapassword',
    loadChildren: () => import('./recuperapassword/recuperapassword.module').then( m => m.RecuperapasswordPageModule)
  },
  {
    path: 'cliente',
    loadChildren: () => import('./cliente/cliente.module').then( m => m.ClientePageModule),
    canActivate: [ClienteGuard]
  },
  {
    path: 'empleado',
    loadChildren: () => import('./empleado/empleado.module').then( m => m.EmpleadoPageModule),
    canActivate: [EmpleadoGuard]
  },  {
    path: 'actualizacion',
    loadChildren: () => import('./actualizacion/actualizacion.module').then( m => m.ActualizacionPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
